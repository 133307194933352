import {computed, ref, watch} from "vue";
import {useUrlSearchParams} from "@vueuse/core";

export const lockers_list = ref<Array<any>>([]);
export const stock_search_product_modal = ref(false);
export const stock_search_product_modal_query = ref('');

watch(stock_search_product_modal_query, (value) => {
    if(value !== ''){
        stock_search_product_modal.value = true;
    }
});
watch(stock_search_product_modal, (value) => {
    if(value === false){
        stock_search_product_modal_query.value = '';
    }
});

export const flat_lockers_list = computed(() => {
    return lockers_list.value.flat();
});
export const selected_locker = computed(() => {
    if (!lockersCode.value) {
        return null;
    }

    let selected_locker = flat_lockers_list.value.find((locker) => {
        return `${locker.col}-${locker.row}` === lockersCode.value;
    });

    if (!selected_locker) {
        selected_locker = flat_lockers_list.value.find((locker) => {
            if (!locker.merge) {
                return false;
            }
            return locker.merge.some((mergeItem) => {
                const parts = mergeItem.split('-');

                if (parts.length < 4) {
                    return false;
                }
                const row = parts[parts.length - 1].substring(1);
                const col = parts[parts.length - 2].substring(1);

                return `${col}-${row}` === lockersCode.value;
            });
        });
    }
    return selected_locker ?? null;
});

export const locker_order_reverse = ref(false);

const locker_params = useUrlSearchParams('history');

export const lockersCode = computed({
    get: () => {
        return locker_params.lockersCode;
    },
    set: (value: string|null) => {
        if(!value){
            locker_params.lockersCode = null;
            return;
        }
        if (value.match(/[a-z]/i)) {
            console.warn('Invalid locker code : ' + value);
            return;
        }
        locker_params.lockersCode = value;
    }
});
